/*
 * @Description:
 * @Author: huacong
 * @Date: 2021-03-27 17:37:21
 * @LastEditTime: 2021-10-27 16:42:18
 * @LastEditors: huacong
 */
import Vue from "vue";
import Vuex from "vuex"; //引入vuex
// import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex); //使用 vuex

const store = new Vuex.Store({
  // plugins: [createPersistedState()],
  state: {
    // 存储token
    Authorization: localStorage.getItem("Authorization")
      ? localStorage.getItem("Authorization")
      : "",
    loginOptions: 1, // 登录方式
    openid: "", // 微信登录id
    unionid: "", // 微信登录id
    userInfo: localStorage.getItem("userInfo")
      ? localStorage.getItem("userInfo")
      : {},
    dragState: false,
    wxName: "",
    talentNoLoginInfo: {}, // 人才第一次登录保存信息
    talentInfo: {}, // 人才基础信息
    talentCer: [], // 人才证书信息
    talentResume: {}, // 人才简历信息
    comId: 0, // 企业ID
    comRealeInfo: {}, // 企业认证信息
    comStaffInfo: {}, // 企业员工个人信息
    comReale: "", // 企业是否已认证
    orderId: "", // 会员支付订单编号
    orderTime: "", // 下单时间
    orderType: "", //订单类型
    orderFee: "", // 订单金额
    codeImg: "", // 二维码路径
    count: 0, //loading应用初始化的数据
    postReturnInfo: {}, // 发布信息后，返回信息
    vueElementLoading: false,
    talcer: "", // 简历证书搜索
    talmajor: "", //
    talcer2: "", // 兼职搜索
    talmajor2: "",
    talcer3: "", // 全职
    talmajor3: "",
    // uploadSrc: "https://zpw.51jiansousou.com/index/file_upload/upfile", // 文件上传地址
    uploadSrc: "https://api.51jiansousou.com/index/file_upload/upfile", // 正式文件上传地址
  },
  mutations: {
    setUserInfo(state, user) {
      state.userInfo = user.userInfo;
      if (!user.userInfo) {
        return;
      }
      localStorage.setItem("userInfo", JSON.stringify(user.userInfo));
    },
    changeLogin(state, user) {
      state.Authorization = user.Authorization;
      localStorage.setItem("Authorization", user.Authorization);
    },
    changeloginOptions(state, user) {
      state.loginOptions = user.loginOptions;
    },
    changeopenid(state, user) {
      state.openid = user.openid;
    },
    changeunionid(state, user) {
      state.unionid = user.unionid;
    },
    changewxName(state, user) {
      state.wxName = user.wxName;
    },
    changetalentNoLoginInfo(state, user) {
      state.talentNoLoginInfo = user.talentNoLoginInfo;
    },
    changetalentInfo(state, user) {
      state.talentInfo = user.talentInfo;
    },
    changetalentCer(state, user) {
      state.talentCer = user.talentCer;
    },
    changetalentResume(state, user) {
      state.talentResume = user.talentResume;
    },
    changecomId(state, user) {
      state.comId = user.comId;
    },
    setcomRealeInfo(state, user) {
      state.comRealeInfo = user.comRealeInfo;
      sessionStorage.setItem("comRealeInfo", JSON.stringify(user.comRealeInfo));
    },
    setcomStaffInfo(state, user) {
      state.comStaffInfo = user.comStaffInfo;
      sessionStorage.setItem("comStaffInfo", JSON.stringify(user.comStaffInfo));
    },
    setcomReale(state, user) {
      state.comReale = user.comReale;
    },
    setorderId(state, user) {
      state.orderId = user.orderId;
    },
    setorderTime(state, user) {
      state.orderTime = user.orderTime;
    },
    setorderType(state, user) {
      state.orderType = user.orderType;
    },
    setorderFee(state, user) {
      state.orderFee = user.orderFee;
    },
    setcodeImg(state, user) {
      state.codeImg = user.codeImg;
    },
    // 拖动条状态
    changeDragState(state, user) {
      state.dragState = user.dragState;
      // localStorage.setItem("dragState", user.dragState);
    },
    changePostReturnInfo(state, user) {
      state.postReturnInfo = user.postReturnInfo;
    },
    changeTalcer(state, user) {
      state.talcer = user.talcer;
    },
    changeTalmajor(state, user) {
      state.talmajor = user.talmajor;
    },
    changeTalcer2(state, user) {
      state.talcer2 = user.talcer2;
    },
    changeTalmajor2(state, user) {
      state.talmajor2 = user.talmajor2;
    },
    changeTalcer3(state, user) {
      state.talcer3 = user.talcer3;
    },
    changeTalmajor3(state, user) {
      state.talmajor3 = user.talmajor3;
    },
    updateCount(state, num) {
      state.count = num;
    },
    updateLoading(state, num) {
      state.vueElementLoading = num;
    },
  },
});
export default store;
