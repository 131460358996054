<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-03-23 17:53:14
 * @LastEditTime: 2021-07-29 11:21:53
 * @LastEditors: huacong
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  created() {
    if (sessionStorage.getItem("store")) {
      let url = window.location.href;
      var index = url.lastIndexOf("/");
      let routerPath = url.substring(index + 1, url.length);
      // 登录页面时，清除state数据
      if (routerPath == "Login") {
        sessionStorage.removeItem("store");
        this.$store.replaceState(Object.assign({}, this.$store.state, {}));
        return;
      }
      // get state赋值
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    // 监听，在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  mounted() {
    window.addEventListener(
      "hashchange",
      () => {
        var currentPath = window.location.hash.slice(1); // 获取输入的路由
        if (this.$router.path !== currentPath) {
          this.$router.push(currentPath); // 动态跳转
        }
      },
      false
    );
  },
};
</script>

<style>
#app {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
}
</style>
