/*
 * @Description:
 * @Author: huacong
 * @Date: 2021-03-29 15:56:00
 * @LastEditTime: 2021-04-26 11:47:46
 * @LastEditors: huacong
 */
// 阻止按钮重复提交
import Vue from "vue";

// 阻止按钮重复提交
const preventRepeatClick = Vue.directive("preventRepeatClick", {
  inserted(el, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  },
});
const smallRepeatClick = Vue.directive("smallRepeatClick", {
  inserted(el, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 1000);
      }
    });
  },
});

export { preventRepeatClick, smallRepeatClick };
