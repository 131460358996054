import Vue from "vue";
import App from "./App.vue";
import "./plugins/element.js";
import "./assets/css/base.css";
import router from "./router/index";
import axios from "axios";
// import axios1 from "axios";
import ElementUI from "element-ui";
import store from "./store/index";
import htmlToPdf from "@/assets/js/htmlToPdf";
import { preventRepeatClick, smallRepeatClick } from "@/assets/js/btnprevent";
Vue.use(htmlToPdf);
Vue.use(preventRepeatClick, smallRepeatClick);
Vue.use(ElementUI);

Vue.config.productionTip = false;

axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("Authorization")) {
      config.headers.token = localStorage.getItem("Authorization");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// axios1.interceptors.request.use(
//   (config) => {
//     if (localStorage.getItem("Authorization")) {
//       config.headers.token = localStorage.getItem("Authorization");
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
let timer = null;
axios.interceptors.response.use(
  (response) => {
    if (response.data.resultCode != "1") {
      if (response.data.resultCode == "5") {
        clearInterval(timer);
        timer = setTimeout(() => {
          // sessionStorage.removeItem("Authorization");
          localStorage.removeItem("Authorization");
          localStorage.removeItem("userInfo");
          ElementUI.Notification({
            title: "提示",
            message: "登录时效已过期！",
            type: "warning",
            duration: 2000,
          });
          // router.push({ path: "/Home/index" });
          router.push({ path: "/Login" });
        }, 500);
      } else if (response.data.resultMsg == "TOKEN不能为空！") {
        ElementUI.Notification({
          title: "提示",
          message: "登录时效已过期,请重新登录！",
          type: "warning",
          duration: 2600,
        });
        router.push({ path: "/Home/index" });
        router.push({ path: "/Login" });
      } else {
        ElementUI.Notification({
          title: "提示",
          message: response.data.resultMsg,
          type: "warning",
          duration: 2600,
        });
      }
    }
    return response;
  },
  (error) => {
    if (error && error.response) {
      console.log(error.response);
      ElementUI.Message({
        message: error.response.statusText,
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

axios.defaults.timeout = 150000;
axios.defaults.baseURL = "https://api.51jiansousou.com/"; // 正式
// axios.defaults.baseURL = "http://zpw.51jiansousou.com/";

Vue.prototype.$http = axios;
// Vue.prototype.$http1 = axios1;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
