import Vue from "vue";
import Router from "vue-router";
const Home = () => import("@/view/home/home");
const Login = () => import("@/view/login/login");
const Notice = () => import("@/view/login/notice");
const appDownload = () => import("@/view/login/appDownload");
const homePage = () => import("@/view/home/index");
const talentPage = () => import("@/view/talentPage/talentPage"); // 人才页面
const talentIndex = () => import("@/view/talentPage/index");
const firstResime = () => import("@/view/talentPage/firstResume"); // 首次登陆后，
const postStar = () => import("@/view/talentPage/postStar"); //收藏职位
const talentResume = () => import("@/view/talentPage/talentResume"); //编辑简历
const resumePre = () => import("@/view/talentPage/resumePre"); // 简历
const editPassword = () => import("@/view/talentPage/editPassword"); // 修改密码
const editPhone = () => import("@/view/talentPage/editPhone"); // 修改手机
const realName = () => import("@/view/talentPage/realName"); // 实名认证
const message = () => import("@/view/talentPage/message");
const sendRecord = () => import("@/view/talentPage/sendRecord");
const downRecord = () => import("@/view/talentPage/downRecord"); //
const weChatBind = () => import("@/view/talentPage/weChatBind"); // 关联微信
const comPage = () => import("@/view/comPage/comPage"); // 企业页面
const comIndex = () => import("@/view/comPage/index"); // 企业首页
const comFirstLogin = () => import("@/view/comPage/completeInfo"); // 关联企业信息页
const comHome = () => import("@/view/comPage/account/comHome"); // 猎头主页
const personal = () => import("@/view/comPage/account/personal"); // 个人信息编辑页
const lookTalResume = () => import("@/view/comPage/lookResume/lookTalResume"); // 查看简历
const accountSafety = () => import("@/view/comPage/account/accountSafety"); //企业账户安全
const realCenter = () => import("@/view/comPage/account/realCenter"); // 认证中心
const comrealSel = () => import("@/view/comPage/account/comrealSel"); // 认证方式选择
const haveComReal = () => import("@/view/comPage/account/haveComReal"); // 已有企业认证
const aftercomrealSel = () => import("@/view/comPage/account/aftercomrealSel"); // 认证后认证方式选择
const afterhaveComReal = () =>
  import("@/view/comPage/account/afterhaveComReal"); // 认证后已有企业认证
const aftercompleteInfo = () => import("@/view/comPage/aftercompleteInfo"); // 认证后关联企业信息页
const postType = () => import("@/view/comPage/postJob/postType"); // 发布类型选择
const postOne = () => import("@/view/comPage/postJob/postOne"); // 发布第一步 填写内容
const postTwo = () => import("@/view/comPage/postJob/postTwo"); // 发布 底部关联企业信息 提交按钮页
const postThree = () => import("@/view/comPage/postJob/postThree"); // 发布后跳转页
const recruitInfo = () => import("@/view/comPage/infoManage/recruitInfo"); // 信息管路页面
const vipIndex = () => import("@/view/comPage/vipService/vipIndex"); // 会员首页
const vipOrder = () => import("@/view/comPage/vipService/vipOrder"); // 会员订单
const vipPay = () => import("@/view/comPage/vipService/vipPay"); // 会员支付
const weChatPay = () => import("@/view/comPage/vipService/weChatPay"); // 微信支付页面
const receipt = () => import("@/view/comPage/resume/receipt"); // 收到的简历
const downloadResume = () => import("@/view/comPage/resume/downloadResume"); // 下载的简历
const smartResume = () => import("@/view/comPage/resume/smartResume"); // 智能搜索简历
const comMessage = () => import("@/view/comPage/message/message"); // 企业信息管理
const partTimeIndex = () => import("@/view/partTime/index"); // 兼职主页
const fullTimeIndex = () => import("@/view/fullTime/index"); // 全职主页
const resumeLibrary = () => import("@/view/resume/index"); // 简历库
const comList = () => import("@/view/comlist/index"); // 企业列表
const emailAffirm = () => import("@/view/common/emailAffirm"); // 邮箱确认
const comDetail = () => import("@/view/comlist/comDetail"); // 企业详情
const fullTimeInfo = () => import("@/view/postInfo/fullTimeInfo"); // 全职详情
const partTimeInfo = () => import("@/view/postInfo/partTimeInfo"); // 兼职详情
const recommeInfo = () => import("@/view/postInfo/recommeInfo"); // 求职详情
const aptitudeIndex = () => import("@/view/aptitude/aptitudeIndex"); // 资质列表
const aptitudeDetail = () => import("@/view/aptitude/aptitudeDetail"); // 资质详情
const consultingDetail = () => import("@/view/consulting/consultingDetail"); // 建讯详情
const consultingIndex = () => import("@/view/consulting/consultingIndex"); // 建讯列表
const partCom = () => import("@/view/partCom/partCom"); // 企业招兼职
const comfeeback = () => import("@/view/comPage/message/comfeeback"); // 企业用户反馈
const talfeeback = () => import("@/view/talentPage/talfeeback"); // 人才用户反馈

Vue.use(Router);
const router = new Router({
  linkActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/Home/index",
      component: Home,
    },
    {
      path: "/Home",
      name: "/Home",
      component: Home,
      children: [
        { path: "index", component: homePage },
        {
          path: "partTimeIndex",
          name: "partTimeIndex",
          component: partTimeIndex,
        },
        {
          path: "fullTimeIndex",
          name: "fullTimeIndex",
          component: fullTimeIndex,
        },
        { path: "resumeLibrary", component: resumeLibrary },
        { path: "comList", name: "comList", component: comList },
        { path: "comDetail", name: "comDetail", component: comDetail },
        { path: "fullTimeInfo", name: "fullTimeInfo", component: fullTimeInfo },
        { path: "partTimeInfo", name: "partTimeInfo", component: partTimeInfo },
        { path: "recommeInfo", name: "recommeInfo", component: recommeInfo },
        {
          path: "aptitudeIndex",
          name: "aptitudeIndex",
          component: aptitudeIndex,
        },
        {
          path: "aptitudeDetail",
          name: "aptitudeDetail",
          component: aptitudeDetail,
        },
        {
          path: "consultingDetail",
          name: "consultingDetail",
          component: consultingDetail,
        },
        {
          path: "consultingIndex",
          name: "consultingIndex",
          component: consultingIndex,
        },
        {
          path: "partCom",
          name: "partCom",
          component: partCom,
        },
        {
          path: "lookTalResume",
          name: "lookTalResume",
          component: lookTalResume,
        },
      ],
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
    },
    {
      path: "/Notice",
      name: "Notice",
      component: Notice,
    },
    {
      path: "/appDownload",
      name: "appDownload",
      component: appDownload,
    },
    { path: "/emailAffirm", name: "emailAffirm", component: emailAffirm },
    // 人才个人页面
    {
      path: "/talentPage",
      name: "talentPage",
      component: talentPage,
      children: [
        { path: "index", component: talentIndex },
        { path: "talentResume", component: talentResume },
        { path: "resumePre", component: resumePre },
        { path: "editPassword", component: editPassword },
        { path: "editPhone", component: editPhone },
        { path: "realName", component: realName },
        { path: "message", component: message },
        { path: "sendRecord", component: sendRecord },
        { path: "downRecord", component: downRecord },
        { path: "weChatBind", component: weChatBind },
        { path: "firstResume", component: firstResime },
        { path: "postStar", component: postStar },
        { path: "talfeeback", component: talfeeback },
      ],
    },
    // 企业、猎头个人中心页面
    {
      path: "/comPage",
      name: "comPage",
      component: comPage,
      children: [
        { path: "index", component: comIndex },
        { path: "comHome", component: comHome },
        { path: "personal", component: personal },
        { path: "accountSafety", component: accountSafety },
        { path: "realCenter", component: realCenter },
        { path: "comrealSel", component: comrealSel },
        { path: "haveComReal", component: haveComReal },
        { path: "comFirstLogin", component: comFirstLogin },
        { path: "aftercomrealSel", component: aftercomrealSel },
        { path: "afterhaveComReal", component: afterhaveComReal },
        { path: "aftercompleteInfo", component: aftercompleteInfo },
        { path: "postType", component: postType },
        { path: "postOne", component: postOne },
        { path: "postTwo", component: postTwo },
        { path: "postThree", component: postThree },
        { path: "recruitInfo", component: recruitInfo },
        { path: "vipIndex", component: vipIndex },
        { path: "vipOrder", component: vipOrder },
        { path: "vipPay", component: vipPay },
        { path: "weChatPay", component: weChatPay },
        { path: "receipt", component: receipt },
        { path: "downloadResume", component: downloadResume },
        { path: "smartResume", component: smartResume },
        { path: "comMessage", component: comMessage },
        { path: "comfeeback", component: comfeeback },
      ],
    },
    // 未知路由重定向首页
    { path: "*", redirect: "/" },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
